import { PipeTransform } from '@angular/core';
var RandomPipe = /** @class */ (function () {
    function RandomPipe() {
    }
    RandomPipe.prototype.transform = function (value) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        if (!args[0] || !args[0][value]) {
            return 'unknown';
        }
        return args[0][value][Math.floor(Math.random() * args[0][value].length)];
    };
    RandomPipe.pipeName = 'random';
    return RandomPipe;
}());
export { RandomPipe };
