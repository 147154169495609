var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { TranslationLoader } from 'angular-translator';
var TranslationLoaderCalendar = /** @class */ (function (_super) {
    __extends(TranslationLoaderCalendar, _super);
    function TranslationLoaderCalendar() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.translations = {
            de: {
                'month.0': 'Januar',
                'month.1': 'Februar',
                'month.2': 'März',
                'month.3': 'April',
                'month.4': 'Mai',
                'month.5': 'Juni',
                'month.6': 'Juli',
                'month.7': 'August',
                'month.8': 'September',
                'month.9': 'Oktober',
                'month.10': 'November',
                'month.11': 'Dezember',
            },
            en: {
                'month.0': 'January',
                'month.1': 'February',
                'month.2': 'March',
                'month.3': 'April',
                'month.4': 'May',
                'month.5': 'June',
                'month.6': 'Juli',
                'month.7': 'August',
                'month.8': 'September',
                'month.9': 'October',
                'month.10': 'November',
                'month.11': 'December',
            }
        };
        return _this;
    }
    TranslationLoaderCalendar.prototype.load = function (_a) {
        var language = _a.language;
        if (!this.translations[language]) {
            return Promise.reject('Translations for language ' + language + ' are not defined');
        }
        return Promise.resolve(this.translations[language]);
    };
    return TranslationLoaderCalendar;
}(TranslationLoader));
export { TranslationLoaderCalendar };
