/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "angular-translator/src/TranslatePipe";
import * as i2 from "angular-translator/src/Translator";
import * as i3 from "angular-translator/src/TranslatorContainer";
import * as i4 from "angular-translator/src/TranslateLogHandler";
import * as i5 from "@angular/common";
import * as i6 from "angular-translator/src/TranslatorModule";
import * as i7 from "./months.component";
var styles_MonthComponent = [];
var RenderType_MonthComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MonthComponent, data: {} });
export { RenderType_MonthComponent as RenderType_MonthComponent };
function View_MonthComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""])), i0.ɵpid(0, i1.TranslatePipe, [i2.Translator, i3.TranslatorContainer, i4.TranslateLogHandler])], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(("month." + _v.context.index))); _ck(_v, 1, 0, currVal_0); }); }
export function View_MonthComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MonthComponent_1)), i0.ɵdid(2, 278528, null, 0, i5.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = i0.ɵEMPTY_ARRAY.constructor(12); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_MonthComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "months", [], null, null, null, View_MonthComponent_0, RenderType_MonthComponent)), i0.ɵprd(5120, null, i2.Translator, i6.createTranslator, [i3.TranslatorContainer, i6.TRANSLATOR_MODULE]), i0.ɵdid(2, 49152, null, 0, i7.MonthComponent, [], null, null), i0.ɵprd(256, null, i6.TRANSLATOR_MODULE, "calendar", [])], null, null); }
var MonthComponentNgFactory = i0.ɵccf("months", i7.MonthComponent, View_MonthComponent_Host_0, {}, {}, []);
export { MonthComponentNgFactory as MonthComponentNgFactory };
